var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KTCodePreview',{staticClass:"overflow-hidden",staticStyle:{"height":"auto"},attrs:{"title":"Sức bán theo Phân khúc giá"},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('b-container',{ref:"barChartContainer",attrs:{"fluid":""}},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nhập cửa hàng","label-class":"font-weight-bold"}},[_c('MultiselectOptionV2',{attrs:{"id":"store-options","model":_vm.selectedStores,"options":_vm.filteredStoreList,"suggestion-name":'name',"trackBy":'id',"placeholder":'cửa hàng',"select-all-option":true},on:{"update:model":function($event){_vm.selectedStores=$event},"select":function($event){return _vm.selectResourceHandler({
                  option: $event,
                  options: _vm.filteredStoreList,
                  trackBy: 'id',
                  filteredOptions: _vm.filteredStoreList,
                  checked: true,
                })},"remove":function($event){return _vm.selectResourceHandler({
                  option: $event,
                  options: _vm.filteredStoreList,
                  trackBy: 'id',
                  filteredOptions: _vm.filteredStoreList,
                  checked: false,
                })},"searchChange":function($event){return _vm.onInputChangeStore($event)}}})],1)],1),_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nhập ngày bắt đầu/Theo ngày","label-class":"font-weight-bold"}},[_c('date-picker',{staticClass:"form-control form-control-sm",attrs:{"size":"sm","config":_vm.dpConfig,"id":"report-start-date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nhập ngày kết thúc","label-class":"font-weight-bold"}},[_c('date-picker',{staticClass:"form-control form-control-sm",attrs:{"size":"sm","config":_vm.dpConfig,"id":"report-end-date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('b-col',{attrs:{"md":"1","sm":"12"}},[_c('b-form-group',[_c('b-button',{attrs:{"size":"sm","variant":"primary","disabled":!!(
                  _vm.isLoading.dataCategoriesMonthly &&
                  _vm.isLoading.dataCategoriesStores &&
                  _vm.isLoading.dataDailyBarChart &&
                  _vm.isLoading.dataGroupedDaily
                )},on:{"click":_vm.fetchData}},[_vm._v("Lọc ")])],1)],1)],1),_c('b-row',{staticClass:"mb-8"},[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('AboriginalTreeselect',{attrs:{"name":"danh mục","model":_vm.selectedCategories,"options":_vm.options,"loadOptions":_vm.loadOptions,"multiple":true},on:{"update:model":(value) => (_vm.selectedCategories = value)}})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}}),_c('b-col',{attrs:{"md":"1"}})],1)],1),_c('b-row',{staticClass:"shadow-sm p-3 mb-4 bg-white rounded"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('D3ArrayChartContainer',{staticClass:"mb-4",attrs:{"id":"bi-report-price-segment-donut-chart","is-loading":_vm.isLoading.dataPriceSegmentDonutChart,"chart-height":_vm.chartHeight,"title":"Tỷ trọng - Phân khúc giá","has-legends":false}})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('D3ArrayChartContainer',{staticClass:"mb-4",attrs:{"id":"bi-report-price-segment-bar-chart","is-loading":_vm.isLoading.dataTopTenSoldParentProducts,"chart-height":_vm.chartHeight,"title":"Top 10 Sản phẩm bán Tốt"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }