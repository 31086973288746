<template>
  <KTCodePreview
    title="Sức bán theo Phân khúc giá"
    class="overflow-hidden"
    style="height: auto"
  >
    <template v-slot:preview>
      <b-container fluid ref="barChartContainer">
        <b-row class="align-items-end">
          <b-col md="5" sm="12">
            <b-form-group label="Nhập cửa hàng" label-class="font-weight-bold">
              <MultiselectOptionV2
                id="store-options"
                :model.sync="selectedStores"
                :options="filteredStoreList"
                :suggestion-name="'name'"
                :trackBy="'id'"
                :placeholder="'cửa hàng'"
                @select="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreList,
                    trackBy: 'id',
                    filteredOptions: filteredStoreList,
                    checked: true,
                  })
                "
                @remove="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreList,
                    trackBy: 'id',
                    filteredOptions: filteredStoreList,
                    checked: false,
                  })
                "
                :select-all-option="true"
                @searchChange="onInputChangeStore($event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" sm="12">
            <b-form-group
              label="Nhập ngày bắt đầu/Theo ngày"
              label-class="font-weight-bold"
            >
              <date-picker
                size="sm"
                class="form-control form-control-sm"
                :config="dpConfig"
                v-model="startDate"
                id="report-start-date"
              ></date-picker>
            </b-form-group>
          </b-col>

          <b-col md="3" sm="12">
            <b-form-group
              label="Nhập ngày kết thúc"
              label-class="font-weight-bold"
            >
              <date-picker
                size="sm"
                class="form-control form-control-sm"
                :config="dpConfig"
                v-model="endDate"
                id="report-end-date"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="1" sm="12">
            <b-form-group>
              <b-button
                size="sm"
                variant="primary"
                @click="fetchData"
                :disabled="
                  !!(
                    isLoading.dataCategoriesMonthly &&
                    isLoading.dataCategoriesStores &&
                    isLoading.dataDailyBarChart &&
                    isLoading.dataGroupedDaily
                  )
                "
                >Lọc
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-8">
          <b-col md="5" sm="12">
            <AboriginalTreeselect
              name="danh mục"
              :model="selectedCategories"
              :options="options"
              :loadOptions="loadOptions"
              :multiple="true"
              @update:model="(value) => (selectedCategories = value)"
            />
          </b-col>
          <b-col md="6" sm="12">
          </b-col>
          <b-col md="1"></b-col>
        </b-row>
      </b-container>
      <b-row class="shadow-sm p-3 mb-4 bg-white rounded">
        <b-col md="6" sm="12">
          <D3ArrayChartContainer
            id="bi-report-price-segment-donut-chart"
            :is-loading="isLoading.dataPriceSegmentDonutChart"
            :chart-height="chartHeight"
            class="mb-4"
            title="Tỷ trọng - Phân khúc giá"
            :has-legends="false"
          />
        </b-col>
        <b-col md="6" sm="12">
          <D3ArrayChartContainer
            id="bi-report-price-segment-bar-chart"
            :is-loading="isLoading.dataTopTenSoldParentProducts"
            :chart-height="chartHeight"
            class="mb-4"
            title="Top 10 Sản phẩm bán Tốt"
          />
        </b-col>
      </b-row>
    </template>
  </KTCodePreview>
</template>
<script>
import {
  DP_CONFIG,
  getAddTimeByUnit,
  getCurrentDateByFormat,
  getEndOfMonth,
  getMonth,
  getStartOfMonth,
} from '@/utils/date';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { formatPrice, makeToastFaile, removeAccents } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { DEBOUNCE, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { swalMixin } from '@/view/mixins';
import { assign, cloneDeep, find, map } from 'lodash';
import debounce from 'debounce';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';
import AboriginalTreeselect from '@/view/base/treeselect/AboriginalTreeselect.vue';
import D3ArrayChartContainer from '@/view/components/bi/D3ArrayChartContainer.vue';
import { d3BarChartMixins } from '@/view/mixins/d3/d3BarChartMixins';
import MultiselectOptionV2 from '@/view/base/multiselect/MultiselectOptionV2.vue';
import vueMultiselectMixin from '@/view/mixins/vue-multiselect.mixins';
import { d3DonutChartMixins } from '@/view/mixins/d3/d3DonutChartMixins';
import { BAR_CHART_LABEL_TYPE } from '@/view/mixins/d3/constants';

export default {
  components: {
    MultiselectOptionV2,
    D3ArrayChartContainer,
    AboriginalTreeselect,
    KTCodePreview,
  },
  mixins: [
    swalMixin,
    d3BarChartMixins,
    vueMultiselectMixin,
    d3DonutChartMixins,
  ],
  data() {
    return {
      dpConfig: DP_CONFIG.date,
      storeName: '',
      storeAddress: '',
      totalProductSold: 0,
      revenuePerStore: 0,
      chartHeight: 680,

      filteredStoreList: [],

      // main data
      dataPriceSegmentDonutChart: [],
      dataTopTenSoldParentProducts: [],

      // loading state for each main data
      isLoading: {
        dataPriceSegmentDonutChart: false,
        dataTopTenSoldParentProducts: false,
      },

      storeKeyword: '',
      storeIds: [],
      startDate: '',
      endDate: '',

      selectedCategories: [],
      options: [],

      selectedStores: [],
    };
  },
  methods: {
    fetchStoresByJobTitle() {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/bi-report/stores/job-title?storeKeyword=${this.storeKeyword}`,
        )
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              return data;
            }
          })
          .then((data) => {
            this.$set(this, 'storeIds', data.data);

            this.filteredStoreList = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            this.selectedStores = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            resolve(); // Resolve the promise once data is fetched and updated
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text:
                error.response.data.message ??
                'Không lấy được danh sách các cửa hàng',
            });
            reject(error); // Reject the promise if there's an error
          });
      });
    },
    debounceStoreInput: debounce(function() {
      this.fetchStoresByJobTitle();
    }, DEBOUNCE.SEARCH_DELAY),
    fetchData() {
      if (!this.startDate || !this.startDate.length) {
        this.popupSwalWarning({
          title: 'Cảnh báo!',
          text: 'Bạn chưa chọn ngày bắt đầu',
        });
        return;
      }

      for (const key in this.isLoading) {
        this.isLoading[key] = true;
      }

      const stores = this.selectedStores
        ? this.selectedStores
            .map((store) => {
              if (store.checked) return Number(store.id);
            })
            .sort()
        : this.storeIds;
      const payload = {
        storeIds: stores,
        startDate: this.startDate,
        endDate: this.endDate,
        categoryIds: [...this.selectedCategories]
          .map((selectedCategory) => Number(selectedCategory))
          .sort(),
      };
      ApiService.post(
        cmdUrl.BIReportResource.salesCapacityPriceSegment.basic,
        payload,
      )
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho Report Doanh thu: theo Ngày',
            });
            return;
          }
          return data.data;
        })
        .then((data) => {
          this.dataPriceSegmentDonutChart = data.priceSegmentProportions.map(
            (proportion) => {
              return {
                name: proportion.name,
                value: proportion.soldProductsPerSegment,
                percentage: proportion.percentage,
              };
            },
          );
          this.dataTopTenSoldParentProducts = data.topTenSoldParentProducts.map(
            (parentProduct) => {
              return {
                parentProductName: parentProduct.parentProductName,
                totalQuantitySoldParentProducts:
                  parentProduct.totalQuantitySoldParentProducts,
              };
            },
          );
          this.$nextTick(() => {
            this.initializeD3DonutChart({
              domElementId: '#bi-report-price-segment-donut-chart',
              data: this.dataPriceSegmentDonutChart,
              width: 400,
              columns: ['name', 'value'],
            });
            this.initializeD3BarChart({
              domElementId: '#bi-report-price-segment-bar-chart',
              data: this.dataTopTenSoldParentProducts,
              margin: {
                top: 30,
                right: 30,
                bottom: 80,
                left: 40,
              },
              axes: {
                xAxis: 'parentProductName',
                yAxis: 'totalQuantitySoldParentProducts',
              },
              textConstruction: {
                parentProductName: 'Tên SP cha',
                totalQuantitySoldParentProducts: 'SL bán',
              },
              yAxisLabel: 'SL bán',
              xAxisLabelType: BAR_CHART_LABEL_TYPE.X_AXIS.BREAK_WORD,
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.dataPriceSegmentDonutChart = false;
          this.isLoading.dataTopTenSoldParentProducts = false;
        });
    },
    onSelectedStore(value) {
      this.storeIds = value.item.id;
    },
    onInputChangeStore(textInput = '') {
      this.searchStore(textInput);
    },

    searchStore(textInput) {
      let options = cloneDeep(this.selectedStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStoreList = map(options, (obj) => {
          return assign(obj, find(this.filteredStoreList, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStoreList, { id: obj.id }));
      });

      this.filteredStoreList = this.filterOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },

    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchParentCategories() {
      return new Promise((resolve, reject) => {
        ApiService.get(cmdUrl.BIReportResource.categories.parents)
          .then(({ data }) => {
            this.options = data.data;
            resolve();
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
            reject(error);
          });
      });
    },
    fetchStoreInfo(id) {
      if (!id) return;
      ApiService.get(`stores/${id}`)
        .then(({ data }) => {
          this.storeName = data.data.name;
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    loadOptions({ action, parentNode, callback }) {
      ApiService.get(
        cmdUrl.BIReportResource.categories.children.replace(
          '{parentId}',
          parentNode.id,
        ),
      )
        .then(({ data }) => {
          if (action === LOAD_CHILDREN_OPTIONS) {
            parentNode.children = data.data;
            callback();
          }
        })
        .catch((error) => {
          this.popupSwalError({
            title: 'Lỗi!',
            text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
          });
        });
    },

    getPayloadOnMount() {
      const currentDate = getCurrentDateByFormat({
        enableCustomCurrentDate: true,
        currentDate: '01/03/2023',
      });
      const designatedEndDate = getAddTimeByUnit({
        amount: 1,
        unit: 'months',
        currentDate,
        enableCustomCurrentDate: true,
      });
      this.startDate = currentDate;
      this.endDate = designatedEndDate;
    },
    storeKeywordChangeHandler(textInput) {
      this.storeKeyword = textInput;
    },
  },

  watch: {
    multiple(newValue) {
      if (newValue) {
        this.value = this.value ? [this.value] : [];
      } else {
        this.value = this.value[0];
      }
    },
  },
  mounted: async function() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo ngành hàng', route: '/bi-report' },
      { title: 'Doanh thu' },
    ]);

    this.getPayloadOnMount();
    await this.fetchParentCategories();
    await this.fetchStoresByJobTitle();
    await this.fetchData();
  },
  computed: {
    formattedRevenue() {
      return formatPrice(this.revenuePerStore);
    },
    payloadMonth() {
      return {
        month: getMonth(this.startDate) + 1,
        startDate: getStartOfMonth(this.startDate),
        endDate: getEndOfMonth(this.startDate),
      };
    },
  },
};
</script>
