import * as d3 from 'd3';

export const d3DonutChartMixins = {
  methods: {
    initializeD3DonutChart({ domElementId, data, width }) {
      const height = Math.min(width, 500);
      const radius = Math.min(width, height) / 2;

      const arc = d3
        .arc()
        .innerRadius(radius * 0.5)
        .outerRadius(radius * 0.8);

      const outerArc = d3
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      const pie = d3
        .pie()
        .padAngle(1 / radius)
        .sort(null)
        .value((d) => d.value);

      const color = d3
        .scaleOrdinal()
        .domain(data.map((d) => d.name))
        .range(
          d3
            .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
            .reverse(),
        );

      const svg = d3
        .select(domElementId)
        .html('')
        .append('svg')
        .attr('width', width * 2)
        .attr('height', height * 1.2)
        .attr('viewBox', [-width / 2, -height / 2, width, height])
        .attr('style', 'max-width: 100%; height: auto;');

      svg
        .append('g')
        .selectAll()
        .data(pie(data))
        .join('path')
        .attr('fill', (d) => color(d.data.name))
        .attr('d', arc)
        .append('title')
        .text(
          (d) =>
            `${
              d.data.name
            }: ${d.data.value.toLocaleString()} (${d.data.percentage.toLocaleString()}%)`,
        )
        .each(function(d) {
          const posA = arc.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 0.99 * (midAngle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          svg
            .select('g')
            .append('polyline')
            .data(pie(data))
            .join()
            .attr('stroke', 'black')
            .style('fill', 'none')
            .attr('stroke-width', 1)
            .attr('points', [posA, posB, posC]);

          svg
            .append('text')
            .text(`${d.data.name} (${d.data.percentage}%)`) // Set the text as the name of the data
            .attr('transform', function() {
              return `translate(${posC})`; // Position the text at posC
            })
            .attr('dy', '0.3em') // Adjust the vertical position above the polygon
            .style('text-anchor', midAngle < Math.PI ? 'start' : 'end');
        });

      return svg.node();
    },
  },
};
